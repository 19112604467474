<template>
  <div id="app" style="padding: 40px">
    <el-form label-width="80px">
      <el-form-item label="邮件标题">
        <el-input v-model="subject"></el-input>
      </el-form-item>
      <el-form-item label="收件人">
        <el-input
          v-model="sendTo"
          style="width: 100%"
          placeholder="支持多个收件人，用逗号隔开(英文逗号)"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮件正文">
        <el-input v-model="body" type="textarea" :rows="10"></el-input>
        <!-- 上传附件功能 -->
        <el-upload
          class="upload-demo"
          action="https://api.jq56.com/api/index/uploadFiles"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :on-error="handleError"
          multiple
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :headers="uploadHeaders"
        >
          <el-button size="small" type="primary">点击上传附件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="float: right" @click="SendMails()"
          >发送</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { SendMail } from "@/api/api";

export default {
  data() {
    return {
      subject: "",
      sendTo: "",
      body: "",
      fileList: [], // 用于存储上传的文件列表
      uploadHeaders: {
        // 如果需要授权，添加 Authorization 或其他需要的 headers
        // Authorization: "Bearer your-token"
      },
    };
  },
  methods: {
    handlePreview(file) {
      console.log("预览文件:", file);
    },
    handleRemove(file, fileList) {
      console.log("删除文件:", file, fileList);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSuccess(response) {
      console.log("上传成功:", response);
      // 假设返回的数据格式是一个数组，包含上传的文件路径
      if (response.data && Array.isArray(response.data)) {
        response.data.forEach((filePath) => {
          this.fileList.push({
            name: filePath.split("/").pop(), // 提取文件名
            url: "https://api.jq56.com" + filePath, // 使用返回的文件路径
          });
        });
      }
    },
    handleError(err) {
      console.error("上传失败:", err);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    SendMails() {
      if (!this.subject || !this.sendTo || !this.body) {
        this.$message.error("请填写完整信息");
      } else {
        const token = localStorage.getItem("token");
        SendMail(
          token,
          this.sendTo,
          this.subject,
          this.body,
          this.fileList
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("邮件发送成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
/* 可以在这里添加组件样式 */
</style>
