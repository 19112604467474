<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <!-- <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getTempEmployees()"
        style="width: 200px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select> -->
      <el-button type="success" @click="(dialogVisible = true), (type = 1)"
        >+添加培训</el-button
      >
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="title" label="培训标题"> </el-table-column>
      <el-table-column prop="content" label="培训内容"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="(dialogVisible = true), (type = 2), (form = scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delTranings(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑/修改弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item label="培训标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="培训内容">
          <el-input v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" type="primary" @click="addTranings()"
          >添 加
        </el-button>
        <el-button v-if="type === 2" type="primary" @click="editTranings()"
          >编 辑
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { addTraning, delTraning, editTraning, getTraining } from "@/api/api";
// import { getEnameList } from "@/api/api";
export default {
  data() {
    return {
      //   ename: "",
      //   enameList: [],
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      form: [],
      dialogVisible: false,
      type: 1, // 1: 添加 2: 编辑
    };
  },
  methods: {
    // getEnameLists() {
    //   getEnameList().then((res) => {
    //     if (res.data.code) {
    //       this.enameList = res.data.data;
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },
    delTranings(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255,255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delTraning(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getTrainings();
          loading.close();
          return;
        }
        this.$message.error("删除失败！" + res.data.msg);
        loading.close();
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getTrainings(); // 请求新页面的数据
    },
    getTrainings() {
      this.loading = true;
      getTraining(this.page, this.pageSize, this.ename).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    addTranings() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255,255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addTraning(token, this.form.title, this.form.content).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.dialogVisible = false;
          this.getTrainings();
          loading.close();
          return;
        }
        this.$message.error("添加失败！" + res.data.msg);
        loading.close();
      });
    },
    editTranings() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255,255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editTraning(token, this.form.id, this.form.title, this.form.content).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.dialogVisible = false;
            this.getTrainings();
            loading.close();
            return;
          }
          this.$message.error("修改失败！" + res.data.msg);
          loading.close();
        }
      );
    },
  },
  created() {
    this.getTrainings();
  },
};
</script>

<style></style>
