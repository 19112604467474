<template>
  <div v-loading="loading">
    <el-row :gutter="20" style="margin: 10px">
      <el-col :span="8" :offset="0">
        <el-card
          style="text-align: center; cursor: pointer"
          @click.native="sendMessageToParent(21)"
        >
          <h2>会员数量</h2>
          <h1 style="color: red">{{ data.membersCount }}</h1>
        </el-card>
      </el-col>
      <el-col :span="8" :offset="0">
        <el-card
          style="text-align: center; cursor: pointer"
          @click.native="sendMessageToParent(73)"
        >
          <h2>员工数量</h2>
          <h1 style="color: red">{{ data.employeeCount }}</h1>
        </el-card>
      </el-col>
      <el-col :span="8" :offset="0">
        <el-card
          style="text-align: center; cursor: pointer"
          @click.native="sendMessageToParent(61)"
        >
          <h2>订单数量</h2>
          <h1 style="color: red">{{ data.orderCount }}</h1>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin: 10px">
      <el-col :span="8" :offset="0">
        <el-card
          style="text-align: center; cursor: pointer"
          @click.native="sendMessageToParent(33)"
        >
          <h2>今日会员消费</h2>
          <h1 style="color: red">{{ data.todayMemberPay }}</h1>
        </el-card>
      </el-col>
      <el-col :span="8" :offset="0">
        <el-card
          style="text-align: center; cursor: pointer"
          @click.native="sendMessageToParent(33)"
        >
          <h2>当月会员消费</h2>
          <h1 style="color: red">{{ data.monthMemberPay }}</h1>
        </el-card>
      </el-col>
      <el-col :span="8" :offset="0">
        <el-card
          style="text-align: center; cursor: pointer"
          @click.native="sendMessageToParent(33)"
        >
          <h2>昨日会员消费</h2>
          <h1 style="color: red">{{ data.yesterdayMemberPay }}</h1>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getStatistics } from "@/api/api";
export default {
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  methods: {
    sendMessageToParent(code) {
      // 使用 $emit 向父组件发送事件
      this.$emit("open", code);
    },
    getStatistic() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getStatistics(token).then((res) => {
        if (res.data.code === 200) {
          this.data = res.data.data;
          this.loading = false;
          return;
        }
        this.loading = false;
        this.$message.error("获取数据失败" + res.data.msg);
      });
    },
  },
  created() {
    this.getStatistic();
  },
};
</script>

<style></style>
