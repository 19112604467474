<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id" width="55"> </el-table-column>
      <el-table-column prop="orderNo" label="充值订单号"> </el-table-column>
      <el-table-column prop="user_id" label="用户ID"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="money" label="金额"> </el-table-column>
      <!-- <el-table-column prop="moneys" label="真实金额"> </el-table-column> -->
      <el-table-column prop="order_type" label="订单类型"> </el-table-column>
      <el-table-column prop="addtime" label="申请时间">
        <template slot-scope="scope" v-if="scope.row.addtime">
          {{ formatDate(scope.row.addtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="uptime" label="审核时间">
        <template slot-scope="scope" v-if="scope.row.uptime">
          {{ formatDate(scope.row.uptime) }}
        </template>
      </el-table-column>
      <el-table-column prop="chanid" label="通道">
        <template slot-scope="scope">
          <span v-if="scope.row.chanid === 1" style="color: #2aae67"
            >微信支付</span
          >
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="warning">待支付</el-tag>
          <el-tag v-else-if="scope.row.status === 1" type="success"
            >已支付</el-tag
          >
          <el-tag v-if="scope.row.status === 2" type="danger">支付失败</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
import moment from "moment";
import { GetRechargeList } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.GetRechargeLists(); // 请求新页面的数据
    },
    GetRechargeLists() {
      this.loading = true;
      GetRechargeList(this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    // GetRechargeLists() {
    //   this.loading = true;
    //   const token = localStorage.getItem("token");
    //   GetRechargeList(token, this.currentPage, this.list).then((res) => {
    //     if (res.data.code === 200) {
    //       this.tableData = res.data.data.data;
    //       this.total = res.data.data.total;
    //       this.loading = false;
    //     } else {
    //       this.$message.error(res.data.msg);
    //       this.loading = false;
    //     }
    //   });
    // },
  },
  created() {
    this.GetRechargeLists();
  },
};
</script>

<style>
.page-button {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 10px 15px;
  border-radius: 2px;
  cursor: pointer;
}
.page-button.active-page {
  background-color: #409eff;
  color: white;
}
</style>
