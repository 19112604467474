<template>
  <div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="orderno" label="开票订单"> </el-table-column>
      <el-table-column prop="invoice_no" label="发票流水号"> </el-table-column>
      <el-table-column prop="company_name" label="购买方名称">
      </el-table-column>
      <el-table-column prop="company_taxno" label="购买方税号">
      </el-table-column>
      <el-table-column prop="company_mail" label="购买方邮箱">
      </el-table-column>
      <el-table-column prop="memo" label="发票备注"> </el-table-column>
      <el-table-column prop="goods" label="商品名称"> </el-table-column>
      <el-table-column prop="amount" label="金额"> </el-table-column>
      <!-- <el-table-column prop="rate" label="税率"> </el-table-column> -->
      <el-table-column prop="url" label="发票链接">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 0 || scope.row.status === 1"
            disabled
            type="text"
            >尚未开票</el-button
          >
          <el-button v-else type="text" @click="openUrl(scope.row.url)"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="status" label="创建时间">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status == 1">开票中</el-tag>
          <el-tag type="success" v-else-if="scope.row.status == 2"
            >已开票</el-tag
          >
          <el-tag type="danger" v-else>异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getMemberInvoicesList } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
    };
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getMemberInvoicesLists();
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getMemberInvoicesLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getMemberInvoicesList(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getMemberInvoicesLists();
  },
};
</script>
