<template>
  <div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <!-- <el-table-column prop="id" label="ID"> </el-table-column> -->
      <!-- <el-table-column prop="userid" label="下单用户ID"> </el-table-column> -->
      <el-table-column prop="orderno" label="订单号"> </el-table-column>
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="ehall" label="展馆号"> </el-table-column>
      <el-table-column prop="eloc" label="展位号"> </el-table-column>
      <!-- <el-table-column prop="payment" label="付款方式"> </el-table-column>
      <el-table-column prop="cargo" label="托寄物信息"> </el-table-column>
      <el-table-column prop="cargo_name" label="托寄物名称"> </el-table-column>
      <el-table-column prop="cargo_cbm" label="托寄物体积"> </el-table-column>
      <el-table-column prop="cargo_weight" label="托寄物总体积">
      </el-table-column> -->
      <!-- <el-table-column prop="cargo_count" label="托寄物数量"> </el-table-column>
      <el-table-column prop="type" label="送达方式"> </el-table-column>
      <el-table-column prop="insured" label="保价方式"> </el-table-column>
      <el-table-column prop="service" label="服务方式"> </el-table-column>
      <el-table-column prop="more_serve" label="增值服务"> </el-table-column>
      <el-table-column prop="entrust_contact" label="委托取货人">
      </el-table-column>
      <el-table-column prop="entrust_phone" label="委托取货人电话">
      </el-table-column> -->
      <el-table-column prop="memo" label="备注"> </el-table-column>
      <el-table-column prop="from_name" label="寄件人"> </el-table-column>
      <el-table-column prop="from_phone" label="寄件电话"> </el-table-column>
      <!-- <el-table-column prop="from_address" label="寄件地址"> </el-table-column> -->
      <el-table-column prop="to_name" label="收件人"> </el-table-column>
      <el-table-column prop="to_phone" label="收件电话"> </el-table-column>
      <!-- <el-table-column prop="to_address" label="收件地址"> </el-table-column> -->
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="openInfomationDialog(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <el-dialog
      title="查看订单信息"
      :visible.sync="checkInfomationDialog"
      width="30%"
    >
      <el-descriptions column="2" title="订单信息">
        <el-descriptions-item label="下单用户ID">{{
          Infomation.userid
        }}</el-descriptions-item>
        <el-descriptions-item label="付款方式">{{
          Infomation.payment
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="托寄物信息">{{
          Infomation.cargo
        }}</el-descriptions-item> -->
        <el-descriptions-item label="托寄物名称">{{
          Infomation.cargo_name
        }}</el-descriptions-item>
        <el-descriptions-item label="托寄物体积">{{
          Infomation.cargo_cbm
        }}</el-descriptions-item>
        <el-descriptions-item label="托寄物总体积">{{
          Infomation.cargo_weight
        }}</el-descriptions-item>
        <el-descriptions-item label="托寄物数量">{{
          Infomation.cargo_count
        }}</el-descriptions-item>
        <el-descriptions-item label="送达方式">{{
          Infomation.type
        }}</el-descriptions-item>
        <el-descriptions-item label="保价方式">{{
          Infomation.insured
        }}</el-descriptions-item>
        <el-descriptions-item label="服务方式">{{
          Infomation.service
        }}</el-descriptions-item>
        <el-descriptions-item label="增值服务">{{
          Infomation.more_serve
        }}</el-descriptions-item>
        <el-descriptions-item label="委托取货人">{{
          Infomation.entrust_contact
        }}</el-descriptions-item>
        <el-descriptions-item label="委托取货人电话">{{
          Infomation.entrust_phone
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          Infomation.memo
        }}</el-descriptions-item>
        <el-descriptions-item label="寄件人">{{
          Infomation.from_name
        }}</el-descriptions-item>
        <el-descriptions-item label="寄件电话">{{
          Infomation.from_phone
        }}</el-descriptions-item>
        <el-descriptions-item label="寄件地址">{{
          Infomation.from_address
        }}</el-descriptions-item>
        <el-descriptions-item label="收件人">{{
          Infomation.to_name
        }}</el-descriptions-item>
        <el-descriptions-item label="收件电话">{{
          Infomation.to_phone
        }}</el-descriptions-item>
        <el-descriptions-item label="收件地址">{{
          Infomation.to_address
        }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkInfomationDialog = false">取 消</el-button>
        <el-button type="primary" @click="checkInfomationDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSendOrder } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      checkInfomationDialog: false,
      Infomation: [],
    };
  },
  methods: {
    openInfomationDialog(row) {
      this.Infomation = row;
      this.checkInfomationDialog = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getSendOrders(); // 请求新页面的数据
    },
    getSendOrders() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getSendOrder(token, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取订单失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getSendOrders();
  },
};
</script>

<style></style>
