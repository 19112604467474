<template>
  <div id="app">
    <!-- <HomeComponent /> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 针对整个页面滚动条 */
::-webkit-scrollbar {
  width: 5px; /* 滚动条宽度 */
  height: 10px; /* 滚动条高度 (横向滚动条) */
}

::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条的颜色 */
  border-radius: 10px; /* 圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滚动条在 hover 时的颜色 */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条的背景 */
  border-radius: 10px; /* 圆角 */
}
</style>
