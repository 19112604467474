<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="success" @click="(dialogVisible = true), (form = [])"
        >+添加预存配置</el-button
      >
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="amount" label="充值金额"> </el-table-column>
      <el-table-column prop="gift_amount" label="赠送金额"> </el-table-column>
      <el-table-column prop="is_show" label="是否展示">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show === 0" type="danger">隐藏</el-tag>
          <el-tag v-else-if="scope.row.is_show === 1" type="success"
            >展示</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delPrestoreConfigs(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑/修改弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item label="充值金额">
          <el-input v-model="form.amount" type="number"></el-input>
        </el-form-item>
        <el-form-item label="赠送金额">
          <el-input v-model="form.gift_amount" type="number"></el-input>
        </el-form-item>
        <el-form-item label="是否展示">
          <el-radio-group v-model="form.is_show">
            <el-radio :label="0">隐藏</el-radio>
            <el-radio :label="1">展示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="type === 1"
          type="primary"
          @click="addPrestoreConfigs()"
          >添 加
        </el-button>
        <el-button
          v-if="type === 2"
          type="primary"
          @click="updatePrestoreConfigs()"
          >编 辑
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addPrestoreConfig,
  delPrestoreConfig,
  getPrestore,
  updatePrestoreConfig,
} from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      form: [],
      dialogVisible: false,
      type: 1, // 1: 添加 2: 编辑
    };
  },
  methods: {
    openDialog(row) {
      this.form = row;
      this.dialogVisible = true;
      this.type = 2;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getPrestores(); // 请求新页面的数据
    },
    getPrestores() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getPrestore(token, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    addPrestoreConfigs() {
      if ((this.form.amount, this.form.gift_amount, this.form.is_show)) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addPrestoreConfig(
          this.form.amount,
          this.form.gift_amount,
          this.form.is_show
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.dialogVisible = false;
            this.getPrestores();
            loading.close();
          } else {
            this.$message.error("添加失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    updatePrestoreConfigs() {
      if ((this.form.id, this.form.amount, this.form.gift_amount)) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        updatePrestoreConfig(
          this.form.id,
          this.form.amount,
          this.form.gift_amount,
          this.form.is_show
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.dialogVisible = false;
            this.getPrestores();
            loading.close();
          } else {
            this.$message.error("添加失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    delPrestoreConfigs(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      delPrestoreConfig(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getPrestores();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.getPrestores();
  },
};
</script>

<style></style>
