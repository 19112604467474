<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getTempEmployees()"
        style="width: 200px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        type="success"
        @click="(dialogVisible = true), (form = [])"
        style="margin-left: 10px"
        >+添加临时员工</el-button
      >
      <el-button type="primary" @click="qrcodeDialogVisible = true"
        >+二维码自主填写</el-button
      >
      <el-button type="warning" @click="exportExcel()"
        >将当前数据导出为表格</el-button
      >
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="ename" label="工作展会"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="gender" label="员工性别">
        <template slot-scope="scope">
          <span v-if="scope.row.gender === 1">男</span>
          <span v-else-if="scope.row.gender === 2">女</span>
        </template>
      </el-table-column>
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="phone" label="电话号码"> </el-table-column>
      <el-table-column prop="idcard" label="身份证号"> </el-table-column>
      <el-table-column prop="idcard_front" label="身份证人像面">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 70px"
            :src="scope.row.idcard_front"
            :preview-src-list="[scope.row.idcard_front]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="idcard_opposite" label="身份证国徽面">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 70px"
            :src="scope.row.idcard_opposite"
            :preview-src-list="[scope.row.idcard_opposite]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delTempEmployees(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑/修改弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form label-width="100px" label-position="left">
            <el-form-item label="员工姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="员工性别">
              <el-radio-group v-model="form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择展会">
              <el-select
                v-model="form.ename"
                clearable
                placeholder="请选择展会名称"
                style="width: 100%; margin-left: 10px"
              >
                <el-option
                  v-for="item in enameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工身份证号">
              <el-input v-model="form.idcard"></el-input>
            </el-form-item>
            <el-form-item label="员工手机电话">
              <el-input v-model="form.phone" type="number"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="身份证人像面">
              <ImageUploader
                v-model="form.idcard_front"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
            <el-form-item label="身份证国徽面">
              <ImageUploader
                v-model="form.idcard_opposite"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" type="primary" @click="addTempEmployees()"
          >添 加
        </el-button>
        <el-button v-if="type === 2" type="primary" @click="editTempEmployees()"
          >编 辑
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="二维码添加临时员工"
      :visible.sync="qrcodeDialogVisible"
      width="30%"
    >
      <div style="text-align: center">
        <img
          src="@/assets/jqtemp.png"
          alt=""
          style="width: 300px; height: 300px"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTempEmployee,
  delTempEmployee,
  editTempEmployee,
  getEnameList,
  getTempEmployee,
} from "@/api/api";
import moment from "moment";
import ImageUploader from "./ImageUploader.vue";
import * as XLSX from "xlsx";
export default {
  components: {
    ImageUploader,
  },
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      form: [],
      dialogVisible: false,
      type: 1, // 1: 添加 2: 编辑
      enameList: [],
      ename: "",
      qrcodeDialogVisible: false,
    };
  },
  methods: {
    exportExcel() {
      // 处理数据：映射字段和转换 gender 值
      const processedData = this.orderList.map((item) => {
        return {
          编号: item.id,
          姓名: item.name,
          性别: item.gender === 1 ? "男" : item.gender === 2 ? "女" : "未知",
          英文名: item.ename,
          电话: item.phone,
          身份证号: item.idcard,
          身份证正面: item.idcard_front,
          身份证反面: item.idcard_opposite,
          创建时间: this.formatDate(item.create_time),
          更新时间: item.update_time,
        };
      });

      // 创建工作簿
      const wb = XLSX.utils.book_new();
      // 将数据转换为工作表
      const ws = XLSX.utils.json_to_sheet(processedData);
      // 添加工作表到工作簿
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出 Excel 文件
      XLSX.writeFile(wb, "导出数据.xlsx");
    },
    delTempEmployees(id) {
      const token = localStorage.getItem("token");
      delTempEmployee(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getTempEmployees();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
        }
      });
    },
    editTempEmployees() {
      const loading = this.$loading({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editTempEmployee(
        token,
        this.form.id,
        this.form.name,
        this.form.gender,
        this.form.ename,
        this.form.phone,
        this.form.idcard,
        this.form.idcard_front,
        this.form.idcard_opposite
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功！");
          this.dialogVisible = false;
          this.getTempEmployees();
          loading.close();
        } else {
          this.$message.error("修改失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    addTempEmployees() {
      const loading = this.$loading({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addTempEmployee(
        token,
        this.form.name,
        this.form.gender,
        this.form.ename,
        this.form.phone,
        this.form.idcard,
        this.form.idcard_front,
        this.form.idcard_opposite
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.dialogVisible = false;
          this.getTempEmployees();
          loading.close();
        } else {
          this.$message.error("添加失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.enameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    openDialog(row) {
      this.form = row;
      this.dialogVisible = true;
      this.type = 2;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getTempEmployees(); // 请求新页面的数据
    },
    getTempEmployees() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getTempEmployee(token, this.page, this.pageSize, this.ename).then(
        (res) => {
          if (res.data.code === 200) {
            this.orderList = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
            console.log(this.orderList);
          } else {
            this.$message.error("获取配置失败！" + res.data.msg);
            this.loading = false;
          }
        }
      );
    },
  },
  created() {
    this.getTempEmployees();
    this.getEnameLists();
  },
};
</script>

<style></style>
