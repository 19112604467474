<template>
	<div>
		<el-table :data="tableData" v-loading="loading" style="width: 100%">
			<el-table-column prop="userid" label="下单用户ID"></el-table-column>
			<el-table-column prop="orderno" label="订单号"></el-table-column>
			<el-table-column prop="cname" label="企业名称"></el-table-column>
			<el-table-column prop="contact" label="联系人"></el-table-column>
			<el-table-column prop="phone" label="联系方式"></el-table-column>
			<el-table-column prop="phone" label="联系方式"></el-table-column>
			<el-table-column prop="ename" label="原展会名称"></el-table-column>
			<el-table-column prop="ehall" label="原展馆"></el-table-column>
			<el-table-column prop="eloc" label="原展位"></el-table-column>
			<el-table-column prop="withdraw_time" label="取件时间"></el-table-column>
			<el-table-column prop="new_ename" label="新展会名称"></el-table-column>
			<el-table-column prop="new_ehall" label="新展馆"></el-table-column>
			<el-table-column prop="new_eloc" label="新展位"></el-table-column>
			<el-table-column prop="new_contact" label="新联系人"></el-table-column>
			<el-table-column prop="new_phone" label="新联系方式"></el-table-column>
			<el-table-column prop="send_time" label="派件时间"></el-table-column>
			<el-table-column prop="memo" label="备注"></el-table-column>
			<!-- 			<el-table-column prop="is_pay" label="是否支付">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.is_pay == 1" type="success">已支付</el-tag>
					<el-tag v-else type="warning">未支付</el-tag>
				</template>
			</el-table-column> -->
		</el-table>
	</div>
</template>

<script>
import { getIntservOrder } from '@/api/api';

export default {
	data() {
		return {
			tableData: [],
			loading: false
		};
	},
	methods: {
		getIntservOrders() {
			this.loading = true;
			getIntservOrder().then((res) => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					this.loading = false;
				} else {
					this.$message.error('获取数据失败！');
					this.loading = false;
				}
			});
		}
	},
	created() {
		this.getIntservOrders();
	}
};
</script>

<style></style>
