<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getCreditOrders()"
        style="width: 200px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="user_id" label="下单用户ID"> </el-table-column>
      <el-table-column prop="orderno" label="订单号"> </el-table-column>
      <el-table-column prop="pname" label="租赁商品名称"> </el-table-column>
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="ehall" label="展馆名称"> </el-table-column>
      <el-table-column prop="eloc" label="展位"> </el-table-column>
      <el-table-column prop="rtime" label="租赁时间"> </el-table-column>
      <el-table-column prop="contact" label="联系方式"> </el-table-column>
      <el-table-column prop="phone" label="联系电话"> </el-table-column>
      <el-table-column prop="money" label="订单押金"> </el-table-column>
      <el-table-column prop="is_pay" label="是否支付">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_pay === 0">未支付</el-tag>
          <el-tag type="success" v-if="scope.row.is_pay === 1">已支付</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_accept" label="是否接受">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_accept === 0">未接受</el-tag>
          <el-tag type="success" v-if="scope.row.is_accept === 1"
            >已接受</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="is_done" label="是否完成">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_done === 0">未完成</el-tag>
          <el-tag type="success" v-if="scope.row.is_done === 1">已完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="accept_employee_name" label="接受操作员工">
      </el-table-column>
      <el-table-column prop="accept_employee_phone" label="接受操作员工电话">
      </el-table-column>
      <el-table-column prop="done_employee_name" label="完成订单员工">
      </el-table-column>
      <el-table-column prop="done_employee_phone" label="完成订单员工电话">
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">
            {{ formatDate(scope.row.updatetime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-popconfirm
            title="确定修改订单为已支付吗？"
            @confirm="payCreditOrders(scope.row.id)"
          >
            <el-button slot="reference" type="primary">已支付</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getCreditOrder, getEnameList, payCreditOrder } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      ename: "",
      enameList: [],
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    payCreditOrders(id) {
      payCreditOrder(id).then((res) => {
        if (res.data.code) {
          this.$message.success("修改成功");
          this.getCreditOrders();
          return;
        }
        this.$message.error("修改失败！" + res.data.msg);
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.enameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handlePageChange(page) {
      this.page = page;
      this.getCreditOrders(); // 请求新页面的数据
    },
    getCreditOrders() {
      this.loading = true;
      getCreditOrder(this.page, this.pageSize, this.ename).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getCreditOrders();
    this.getEnameLists();
  },
};
</script>

<style></style>
