<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getCommandLogs()"
        style="width: 200px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        type="success"
        @click="(dialogVisible = true), (form = [])"
        style="margin-left: 10px"
        >+添加特种作业人员</el-button
      >
      <el-button type="primary" @click="qrcodeDialogVisible = true"
        >二维码添加特种作业人员</el-button
      >
      <el-button type="warning" @click="exportExcel()"
        >将当前数据导出为表格</el-button
      >
    </div>
    <el-table :data="orderList" border v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="ename" label="工作展会"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="gender" label="性别">
        <template slot-scope="scope">
          <span v-if="scope.row.gender === 1">男</span>
          <span v-if="scope.row.gender === 2">女</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="nation" label="民族"> </el-table-column>
      <el-table-column prop="born" label="出生日期">
        <template slot-scope="scope">
          {{ formatDate(scope.row.born) }}
        </template>
      </el-table-column> -->
      <el-table-column prop="idcard" label="身份证号"> </el-table-column>
      <!-- <el-table-column prop="idcard_start" label="身份证起始日期">
        <template slot-scope="scope">
          {{ formatDate(scope.row.idcard_start) }}
        </template>
      </el-table-column>
      <el-table-column prop="idcard_end" label="身份证截止日期">
        <template slot-scope="scope">
          {{ formatDate(scope.row.idcard_end) }}
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="live_location" label="当前居住地址">
      </el-table-column> -->
      <el-table-column prop="license_type" label="特种作业类型">
      </el-table-column>
      <el-table-column prop="face_image" label="人像图片">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.face_image"
            :preview-src-list="[scope.row.face_image]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="front_idcard" label="身份证正面图片">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.front_idcard"
            :preview-src-list="[scope.row.front_idcard]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="backend_idcard" label="身份证反面图片">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.backend_idcard"
            :preview-src-list="[scope.row.backend_idcard]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="front_license" label="特种作业证件人像页">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.front_license"
            :preview-src-list="[scope.row.front_license]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="date_license" label="特种作业证件有效期页">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.date_license"
            :preview-src-list="[scope.row.date_license]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="更新时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delCommands(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <el-dialog
      title="添加指挥工"
      fullscreen
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <br />
              <el-select
                v-model="form.gender"
                placeholder="请选择性别"
                style="width: 100%"
              >
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="民族">
              <el-input v-model="form.nation"></el-input>
            </el-form-item>
            <el-form-item label="出生日期">
              <br />
              <el-date-picker
                style="width: 100%"
                v-model="form.born"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="form.idcard"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="身份证有效期开始">
              <br />
              <el-date-picker
                style="width: 100%"
                v-model="form.idcard_start"
                type="date"
                placeholder="选择身份证开始有效期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="身份证有效期截止">
              <br />
              <el-date-picker
                style="width: 100%"
                v-model="form.idcard_end"
                type="date"
                placeholder="选择身份证截止有效期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="当前居住地址">
              <el-input v-model="form.live_location"></el-input>
            </el-form-item>
            <el-form-item label="特种作业证件类型">
              <br />
              <el-select
                v-model="form.license_type"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="头像照片">
              <ImageUploader
                v-model="form.face_image"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
            <el-form-item label="身份证人像面">
              <ImageUploader
                v-model="form.front_idcard"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
            <el-form-item label="身份证国徽面">
              <ImageUploader
                v-model="form.backend_idcard"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-form>
            <el-form-item label="特种作业证件人像页">
              <ImageUploader
                v-model="form.front_license"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
            <el-form-item label="特种作业证件有效期页">
              <ImageUploader
                v-model="form.date_license"
                upload-url="https://api.jq56.com/api/user/uploadImg"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" type="primary" @click="addCommands()"
          >添 加</el-button
        >
        <el-button v-else-if="type === 2" type="primary" @click="editCommands()"
          >编 辑</el-button
        >
      </span>
    </el-dialog>
    <!-- 二维码添加员工 -->
    <el-dialog
      title="二维码添加特种作业人员"
      :visible.sync="qrcodeDialogVisible"
      width="30%"
    >
      <div style="text-align: center">
        <img
          src="@/assets/createcommand.png"
          alt=""
          style="width: 300px; height: 300px"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCommand,
  delCommand,
  editCommand,
  getCommandLog,
  getEnameList,
} from "@/api/api.js";
import moment from "moment";
import ImageUploader from "./ImageUploader.vue";
import * as XLSX from "xlsx";

export default {
  components: {
    ImageUploader,
  },
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      form: [],
      dialogVisible: false,
      type: 1,
      options: [
        {
          value: "叉车司机",
          label: "叉车司机",
        },
        {
          value: "高空作业证",
          label: "高空作业证",
        },
        {
          value: "焊工证",
          label: "焊工证",
        },
        {
          value: "电工证",
          label: "电工证",
        },
        {
          value: "流动式起重机司机驾驶证",
          label: "流动式起重机司机驾驶证",
        },
      ],
      qrcodeDialogVisible: false,
      enameList: [],
    };
  },
  methods: {
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.enameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    exportExcel() {
      // 处理数据：映射字段和转换 gender 值
      const processedData = this.tableData.map((item) => {
        return {
          编号: item.id,
          用户名: item.username,
          姓名: item.nickname,
          联系电话: item.phone,
          创建时间: this.formatDate(item.create_time),
        };
      });

      // 创建工作簿
      const wb = XLSX.utils.book_new();
      // 将数据转换为工作表
      const ws = XLSX.utils.json_to_sheet(processedData);
      // 添加工作表到工作簿
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出 Excel 文件
      XLSX.writeFile(wb, "导出数据.xlsx");
    },
    delCommands(id) {
      const token = localStorage.getItem("token");
      const loading = this.$loading({
        lock: true,
        text: "正在删除",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      delCommand(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getCommandLogs();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    formatUnixToDate(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000); // 如果是秒，需要乘以 1000
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    openDialog(row) {
      this.form = row;
      this.form.born = this.formatUnixToDate(this.form.born);
      this.form.idcard_start = this.formatUnixToDate(this.form.idcard_start);
      this.form.idcard_end = this.formatUnixToDate(this.form.idcard_end);
      this.type = 2;
      this.dialogVisible = true;
    },
    editCommands() {
      const loading = this.$loading({
        lock: true,
        text: "正在修改",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editCommand(
        token,
        this.form.id,
        this.form.name,
        this.form.gender,
        this.form.nation,
        this.form.born,
        this.form.idcard,
        this.form.idcard_start,
        this.form.idcard_end,
        this.form.live_location,
        this.form.license_type,
        this.form.face_image,
        this.form.front_idcard,
        this.form.backend_idcard,
        this.form.front_license,
        this.form.date_license
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功");
          this.dialogVisible = false;
          this.getCommandLogs();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    addCommands() {
      const loading = this.$loading({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addCommand(
        token,
        this.form.name,
        this.form.gender,
        this.form.nation,
        this.form.born,
        this.form.idcard,
        this.form.idcard_start,
        this.form.idcard_end,
        this.form.live_location,
        this.form.license_type,
        this.form.face_image,
        this.form.front_idcard,
        this.form.backend_idcard,
        this.form.front_license,
        this.form.date_license
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.getCommandLogs();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getCommandLogs(); // 请求新页面的数据
    },
    getCommandLogs() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getCommandLog(token, this.page, this.pageSize, this.ename).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getCommandLogs(); // 请求新页面的数据
    this.getEnameLists();
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
