<template>
  <div>
    <div
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <el-card style="width: 400px; text-align: center">
        <img src="@/assets/logo.png" alt="logo" style="width: 200px" />
        <el-form>
          <el-form-item label="请输入账号">
            <el-input v-model="account"></el-input>
          </el-form-item>
          <el-form-item label="请输入密码">
            <el-input v-model="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="Logins()">
              登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api/api";

export default {
  data() {
    return {
      account: "",
      password: "",
    };
  },
  methods: {
    Logins() {
      Login(this.account, this.password).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("登录成功");
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", res.data.data.token); // 存储 token
          localStorage.setItem("username", res.data.data.username); //存储用户名
          localStorage.setItem("avatar", res.data.data.avatar); //存储用户头像
          localStorage.setItem("nickname", res.data.data.nickname); //存储用户头像
          this.$router.push("/");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
