<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="primary" @click="dialogVisible = true"
        >增加机力分类</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="租赁分类名称"> </el-table-column>
      <el-table-column prop="createtime" label="创建日期">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改日期">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="310">
        <template slot-scope="scope">
          <el-button type="warning" @click="openMacDialog(scope.row)"
            >查看归属机力</el-button
          >
          <el-button type="primary" @click="openEditDialog(scope.row)">
            编辑
          </el-button>
          <el-popconfirm
            title="删除后所有归属产品也将被一并删除，确定删除吗？"
            @confirm="delMacTypes(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增机力分类" :visible.sync="dialogVisible" width="30%">
      <el-form label-position="left" inline>
        <el-form-item label="请输入分类名称">
          <el-input v-model="add.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMacTypes()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改机力分类"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form label-position="left" inline>
        <el-form-item label="请输入分类名称">
          <el-input v-model="edit.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editMacTypes()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看对应机力产品 -->
    <el-dialog title="查看归属机力产品" :visible.sync="checkMac" width="80%">
      <div style="margin: 0 0 10px 10px">
        <el-button type="primary" @click="macAddVisible = true"
          >新增机力产品</el-button
        >
      </div>
      <el-table
        :data="macList"
        v-loading="macLoading"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="mid" label="归属机力分类ID"> </el-table-column>
        <el-table-column prop="name" label="产品名称"> </el-table-column>
        <el-table-column prop="load" label="额定载重"> </el-table-column>
        <el-table-column prop="createtime" label="创建日期">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime">{{
              formatDate(scope.row.createtime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updatetime" label="修改日期">
          <template slot-scope="scope">
            <span v-if="scope.row.updatetime">{{
              formatDate(scope.row.updatetime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="primary" @click="openEditMacDialog(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delMacProducts(scope.row.id)"
            >
              <el-button
                type="danger"
                slot="reference"
                style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkMac = false">取 消</el-button>
        <el-button type="primary" @click="checkMac = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增对应机力产品 -->
    <el-dialog title="新增机力产品" :visible.sync="macAddVisible" width="30%">
      <p>当前正在新增分类：{{ mname }}归属产品</p>
      <el-form label-position="left" inline label-width="140px">
        <el-form-item label="请输入产品名称">
          <el-input v-model="macAdd.name"></el-input>
        </el-form-item>
        <el-form-item label="请输入产品额定荷载">
          <el-input v-model="macAdd.load"></el-input>
        </el-form-item>
        <p v-if="macAdd.name && macAdd.load">
          小程序显示示例：{{ macAdd.load }}{{ macAdd.name }}
        </p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="macAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMacProductForMids()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑对应机力产品 -->
    <el-dialog title="编辑机力产品" :visible.sync="macEditVisible" width="30%">
      <el-form label-position="left" inline label-width="140px">
        <el-form-item label="请输入产品名称">
          <el-input v-model="macEdit.name"></el-input>
        </el-form-item>
        <el-form-item label="请输入产品额定荷载">
          <el-input v-model="macEdit.load"></el-input>
        </el-form-item>
        <p v-if="macEdit.name && macEdit.load">
          小程序显示示例：{{ macEdit.load }}{{ macEdit.name }}
        </p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="macEditVisible = false">取 消</el-button>
        <el-button type="primary" @click="editMacProducts()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  addMacProductForMid,
  addMacType,
  delMacProduct,
  delMacType,
  editMacProduct,
  editMacType,
  getMacProductForMid,
  getMacType,
} from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      add: [],
      edit: [],
      editDialogVisible: false,
      checkMac: false,
      macList: [],
      macLoading: false,
      mid: "",
      mname: "",
      macAdd: [],
      macAddVisible: false,
      macEdit: [],
      macEditVisible: false,
    };
  },
  methods: {
    delMacProducts(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delMacProduct(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getMacProductForMids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    editMacProducts() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editMacProduct(
        token,
        this.macEdit.id,
        this.macEdit.mid,
        this.macEdit.name,
        this.macEdit.load
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功！");
          this.macEditVisible = false;
          this.getMacProductForMids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openEditMacDialog(row) {
      this.macEdit = row;
      this.macEditVisible = true;
    },
    addMacProductForMids() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addMacProductForMid(
        token,
        this.mid,
        this.macAdd.name,
        this.macAdd.load
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("新增成功！");
          this.macAddVisible = false;
          this.getMacProductForMids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openMacDialog(row) {
      this.mid = row.id;
      this.mname = row.name;
      this.checkMac = true;
      this.getMacProductForMids();
    },
    getMacProductForMids() {
      this.macLoading = true;
      const mid = this.mid;
      const token = localStorage.getItem("token");
      getMacProductForMid(token, mid).then((res) => {
        if (res.data.code === 200) {
          this.macList = res.data.data;
          this.macLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.macLoading = false;
        }
      });
    },
    openEditDialog(row) {
      this.edit = row;
      this.editDialogVisible = true;
    },
    addMacTypes() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addMacType(token, this.add.name)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.dialogVisible = false;
            this.getMacTypes();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    editMacTypes() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editMacType(token, this.edit.id, this.edit.name)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.editDialogVisible = false;
            this.getMacTypes();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    delMacTypes(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delMacType(token, id)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("删除成功！");
            this.getMacTypes();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    getMacTypes() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getMacType(token)
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.loading = false;
        });
    },
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getMacTypes();
  },
};
</script>

<style></style>
