<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button
        type="success"
        @click="(dialogVisible = true), (form = []), (type = 1)"
      >
        +新增优惠卷</el-button
      >
    </div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <!-- <el-table-column prop="id" label="ID"> </el-table-column> -->
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="money" label="优惠卷金额"> </el-table-column>
      <el-table-column prop="status" label="优惠卷状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="success">未使用</el-tag>
          <el-tag v-if="scope.row.status === 1" type="warning">已使用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delTickets(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑/修改 -->
    <el-dialog title="内容查看" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item v-if="type === 1" label="用户手机号">
          <el-input v-model="form.phone" type="number"></el-input>
        </el-form-item>
        <el-form-item label="优惠卷金额">
          <el-input v-model="form.money" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type == 1" type="primary" @click="addTickets()"
          >添 加</el-button
        >
        <el-button v-if="type == 2" type="primary" @click="editTickets()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addTicket, delTicket, editTicket, getTicket } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogVisible: false,
      form: [],
      type: 1,
    };
  },
  methods: {
    delTickets(id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delTicket(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.dialogVisible = false;
          this.getTickets();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    editTickets() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editTicket(token, this.form.id, this.form.money).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功！");
          this.dialogVisible = false;
          this.getTickets();
          loading.close();
        } else {
          this.$message.error("修改失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    addTickets() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addTicket(token, this.form.phone, this.form.money).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.dialogVisible = false;
          this.getTickets();
          loading.close();
        } else {
          this.$message.error("添加失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    openDialog(row) {
      this.form = row;
      this.type = 2;
      this.dialogVisible = true;
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getTickets(); // 请求新页面的数据
    },
    getTickets() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getTicket(token, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getTickets();
  },
};
</script>

<style></style>
