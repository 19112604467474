<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getRentOrder()"
        style="width: 200px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <a href="https://api.jq56.com/api/admin/exportRentExcel" target="_blank">
        <el-button type="primary" style="margin-left: 10px"
          >下载租赁订单表格</el-button
        >
      </a>
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="rentOrderList"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form
            label-position="left"
            inline
            class="demo-table-expand"
            label-width="120px"
            style="margin-left: 20px"
          >
            <el-form-item label="下单用户id">
              <span>{{ props.row.userid }}</span>
            </el-form-item>
            <el-form-item label="下单联系人">
              <span>{{ props.row.contact }}</span>
            </el-form-item>
            <el-form-item label="联系电话">
              <span>{{ props.row.phone }}</span>
            </el-form-item>
            <el-form-item label="归属展会">
              <span>{{ props.row.ename }}</span>
            </el-form-item>
            <el-form-item label="展商名称">
              <span>{{ props.row.ebusiness }}</span>
            </el-form-item>
            <el-form-item label="展商位置">
              <span>{{ props.row.ehall }}展馆{{ props.row.eloc }}号位</span>
            </el-form-item>
            <el-form-item label="预计使用时间">
              <span>{{ props.row.usetime }}</span>
            </el-form-item>
            <el-form-item label="租赁设备数量">
              <span>{{ props.row.count }}台/辆</span>
            </el-form-item>
            <el-form-item label="订单是否付款">
              <el-tag v-if="props.row.is_pay == 1" type="success"
                >已付款</el-tag
              >
              <el-tag v-else type="danger">未付款</el-tag>
            </el-form-item>
            <el-form-item label="是否被员工接受">
              <el-tag v-if="props.row.is_accept == 1" type="success"
                >已接受</el-tag
              >
              <el-tag v-else type="danger">未接受</el-tag>
            </el-form-item>
            <el-form-item v-if="props.row.is_accept == 1" label="接受处理员工">
              <span>{{ props.row.employee_name }}</span>
            </el-form-item>
            <el-form-item v-if="props.row.is_accept == 1" label="处理员工电话">
              <span>{{ props.row.employee_phone }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <!-- <el-table-column prop="userid" label="下单用户id"> </el-table-column> -->
      <el-table-column prop="ename" label="租赁展馆"> </el-table-column>
      <el-table-column prop="orderid" label="租赁订单号"> </el-table-column>
      <el-table-column prop="product" label="租赁设备"> </el-table-column>
      <el-table-column prop="load" label="额定荷载重量"> </el-table-column>
      <!-- <el-table-column prop="ebusiness" label="展商名称"> </el-table-column> -->
      <!-- <el-table-column prop="ename" label="归属展会"> </el-table-column> -->
      <!-- <el-table-column label="展商位置">
        <template slot-scope="scope">
          <span>{{ scope.row.ehall }}展馆{{ scope.row.eloc }}号位</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="usetime" label="预计使用时间"> </el-table-column> -->
      <!-- <el-table-column prop="count" label="租赁设备数量"> </el-table-column> -->
      <el-table-column prop="money" label="订单价格"> </el-table-column>
      <el-table-column prop="memo" label="订单备注"> </el-table-column>
      <el-table-column prop="is_accept" label="是否接受处理">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_accept == 1" type="success">已接受</el-tag>
          <el-tag v-else type="danger">未接受</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="employee_name" label="处理员工"> </el-table-column>
      <el-table-column prop="employee_phone" label="员工电话">
      </el-table-column>
      <el-table-column prop="is_pay" label="是否支付">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_pay == 1" type="success">已支付</el-tag>
          <el-tag v-else type="danger">未支付</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="270">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_pay === 0"
            type="warning"
            @click="payRentOrders(scope.row.orderid)"
            >已支付</el-button
          >
          <el-button v-if="scope.row.is_pay === 1" type="warning" disabled
            >已支付</el-button
          >
          <el-button
            v-if="scope.row.is_pay === 0"
            type="primary"
            @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button v-if="scope.row.is_pay === 1" type="primary" disabled
            >编辑</el-button
          >
          <el-popconfirm
            v-if="scope.row.is_pay === 0"
            title="确定删除该订单吗？"
            @confirm="delRentOrders(scope.row.orderid)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
          <el-button v-else slot="reference" type="danger" disabled
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑订单" :visible.sync="dialogVisible" width="80%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form label-width="100px">
            <el-form-item label="租赁设备">
              <el-input v-model="editOrderInfo.product"></el-input>
            </el-form-item>
            <el-form-item label="额定荷载">
              <el-input v-model="editOrderInfo.load"></el-input>
            </el-form-item>
            <el-form-item label="展会名称">
              <el-input v-model="editOrderInfo.ename"></el-input>
            </el-form-item>
            <el-form-item label="使用时间">
              <el-input v-model="editOrderInfo.usetime"></el-input>
              <span>(请严格按照格式修改 例如：1小时或1天)</span>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form label-width="100px">
            <el-form-item label="展馆号">
              <el-input v-model="editOrderInfo.ehall"></el-input>
            </el-form-item>
            <el-form-item label="展位号">
              <el-input v-model="editOrderInfo.eloc"></el-input>
            </el-form-item>
            <el-form-item label="设备数量">
              <el-input v-model="editOrderInfo.count"></el-input>
            </el-form-item>
            <el-form-item label="订单价格">
              <el-input v-model="editOrderInfo.money"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRentOrder()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  delRentOrder,
  editRentOrders,
  getEnameList,
  getRentOrders,
  payRentOrder,
} from "@/api/api";
export default {
  data() {
    return {
      rentOrderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      currentPage: 1,
      dialogVisible: false,
      editOrderInfo: [],
      ename: "",
      enameList: [],
    };
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
      this.getRentOrder(); // 请求新页面的数据
    },
    payRentOrders(orderid) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      payRentOrder(token, orderid).then((res) => {
        if (res.data.code === 200) {
          this.getRentOrder();
          loading.close();
          return;
        }
        this.$message.error("操作失败！" + res.data.msg);
        loading.close();
      });
    },
    openEditDialog(row) {
      this.editOrderInfo = row;
      this.dialogVisible = true;
    },
    // getRentOrder() {
    //   this.loading = true;
    //   const token = localStorage.getItem("token");
    //   getRentOrders(token, this.page, this.list)
    //     .then((res) => {
    //       if (res.data.code === 200) {
    //         this.rentOrderList = res.data.data.data;
    //         this.total = res.data.data.total;
    //         this.loading = false;
    //       } else {
    //         this.$message.error(res.data.msg);
    //         this.loading = false;
    //       }
    //     })
    //     .catch((res) => {
    //       this.$message.error(res.data.msg);
    //       this.loading = false;
    //     });
    // },
    getRentOrder() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getRentOrders(token, this.page, this.pageSize, this.ename).then((res) => {
        if (res.data.code === 200) {
          this.rentOrderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    editRentOrder() {
      const token = localStorage.getItem("token");
      editRentOrders(
        token,
        this.editOrderInfo.orderid,
        this.editOrderInfo.product,
        this.editOrderInfo.load,
        this.editOrderInfo.ename,
        this.editOrderInfo.usetime,
        this.editOrderInfo.ehall,
        this.editOrderInfo.eloc,
        this.editOrderInfo.count,
        this.editOrderInfo.money
      )
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.dialogVisible = false;
            this.getRentOrder();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    delRentOrders(orderid) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const token = localStorage.getItem("token");
      delRentOrder(token, orderid)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.getRentOrder();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getRentOrder();
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.enameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getRentOrder();
    this.getEnameLists();
  },
};
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
