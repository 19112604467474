<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="success" @click="dialogVisible = true"
        >新增管理员</el-button
      >
    </div>
    <el-table :data="userList" border stripe style="width: 100%">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">{{
          formatDate(scope.row.create_time)
        }}</template>
      </el-table-column>
      <el-table-column prop="nickname" label="使用人姓名"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1" type="success">启用</el-tag>
          <el-tag v-else type="danger">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 1"
            type="danger"
            @click="setStatusForAdmins(scope.row.username, '0')"
            >停用</el-button
          >
          <el-button
            v-else
            type="primary"
            @click="setStatusForAdmins(scope.row.username, '1')"
            >启用</el-button
          >
          <el-popconfirm
            title="管理员删除后无法恢复！您确定要这样做吗?"
            @confirm="delAdmins(scope.row.username)"
          >
            <el-button slot="reference" type="warning" style="margin-left: 10px"
              >删除用户</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增管理员 -->
    <el-dialog title="新增管理员" :visible.sync="dialogVisible" width="30%">
      <el-form label-width="100px">
        <el-form-item label="用户名">
          <el-input v-model="reg.username"></el-input>
        </el-form-item>
        <el-form-item label="用户登录密码">
          <el-input v-model="reg.password"></el-input>
        </el-form-item>
        <el-form-item label="使用员工姓名">
          <el-input v-model="reg.nickname"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="registers()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { delAdmin, getAdminList, register, setStatusForAdmin } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      username: "",
      nickname: "",
      phone: "",
      userList: [],
      reg: [],
      dialogVisible: false,
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getAdminLists() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      getAdminList(token).then((res) => {
        if (res.data.code === 200) {
          this.userList = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    registers() {
      if (!this.reg.username || !this.reg.password || !this.reg.nickname) {
        this.$message.error("请输入完整信息");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      register(this.reg.username, this.reg.password, this.reg.nickname).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.dialogVisible = false;
            this.getAdminLists();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        }
      );
    },
    delAdmins(username) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delAdmin(token, username).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getAdminLists();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    setStatusForAdmins(username, status) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      setStatusForAdmin(token, username, status).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("设置成功");
          this.getAdminLists();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.getAdminLists();
  },
};
</script>

<style></style>
