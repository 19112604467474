<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-select
        v-model="ename"
        clearable
        placeholder="请选择展会名称"
        @change="getRentProducts()"
        style="width: 200px"
      >
        <el-option
          v-for="item in enameList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        type="success"
        style="margin: 0px 10px 10px 10px"
        @click="DialogVisible = true"
        >添加机力租赁</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <!-- <el-table-column prop="id" label="id"> </el-table-column> -->
      <el-table-column prop="ename" label="租赁展会"> </el-table-column>
      <el-table-column prop="product" label="机力名称"> </el-table-column>
      <el-table-column prop="load" label="额定荷载">
        <template slot-scope="scope">
          <div
            v-for="item in JSON.parse(scope.row.load)"
            :key="item"
            style="display: inline-block; margin: 0px 5px"
          >
            <el-tag>{{ item }}吨</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格"> </el-table-column>
      <el-table-column prop="createtime" label="创建日期">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新日期">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <!-- <el-button type="warning" @click="openCheckInfo(scope.row.eid)"
            >查看租赁商品</el-button
          > -->
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delRentProducts(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 添加/编辑机力租赁对话框 -->
    <el-dialog title="新增机力租赁" :visible.sync="DialogVisible" width="30%">
      <el-form>
        <el-form-item label="请输入机力租赁名称">
          <br />
          <el-select
            v-model="form.ename"
            clearable
            placeholder="请选择展会名称"
            style="width: 100%"
          >
            <el-option
              v-for="item in enameList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请输入机力租赁名称">
          <el-input v-model="form.product"></el-input>
        </el-form-item>
        <el-form-item label="请输入机力租赁价格">
          <el-input v-model="form.price" type="number"></el-input>
        </el-form-item>
        <el-form-item label="请选择机力额定荷载">
          <el-input
            v-model="form.load"
            placeholder="请输入荷载数字，以逗号分割，例如：1,2,3"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" type="primary" @click="addRentProducts()"
          >添 加</el-button
        >
        <el-button
          v-else-if="type === 2"
          type="primary"
          @click="editRentProducts()"
          >修 改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addRentProduct,
  delRentProduct,
  editRentProduct,
  getEnameList,
  getRentProduct,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      DialogVisible: false,
      form: [],
      type: 1,
      enameList: [],
      ename: "",
    };
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
      this.getRentProducts(); // 请求新页面的数据
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code) {
          this.enameList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    openEditDialog(row) {
      this.type = 2;
      console.log("原始 row.load:", row.load);
      console.log("原始 row.load 类型:", typeof row.load);

      this.form = { ...row }; // 浅拷贝，避免引用问题

      if (typeof row.load === "string") {
        try {
          const parsedLoad = JSON.parse(row.load); // 解析 JSON 格式
          if (Array.isArray(parsedLoad)) {
            this.form.load = parsedLoad.join(","); // 转换为字符串
          } else {
            this.form.load = row.load.split(",").join(","); // 普通字符串处理
          }
        } catch {
          this.form.load = row.load.split(",").join(",");
        }
      } else if (Array.isArray(row.load)) {
        this.form.load = row.load.join(",");
      } else {
        this.form.load = "";
      }

      console.log("最终的 form.load:", this.form.load); // 调试输出
      this.DialogVisible = true;
    },
    //格式化Unix时间戳
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // getRentProducts() {
    //   this.loading = true;
    //   const token = localStorage.getItem("token");
    //   getRentProduct(token).then((res) => {
    //     if (res.data.code === 200) {
    //       this.tableData = res.data.data;
    //       this.loading = false;
    //     } else {
    //       this.$message.error(res.data.msg);
    //       this.loading = false;
    //     }
    //   });
    // },
    getRentProducts() {
      this.loading = true;
      getRentProduct(this.page, this.pageSize, this.ename).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    addRentProducts() {
      const token = localStorage.getItem("token");
      if ((this.form.product, this.form.price, this.form.load)) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        addRentProduct(
          token,
          this.form.ename,
          this.form.product,
          this.form.price,
          this.form.load
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功！");
            this.DialogVisible = false;
            this.getRentProducts();
            loading.close();
          } else {
            this.$message.error("添加失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    editRentProducts() {
      const token = localStorage.getItem("token");
      if ((this.form.product, this.form.price, this.form.load)) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        editRentProduct(
          token,
          this.form.id,
          this.form.ename,
          this.form.product,
          this.form.price,
          this.form.load
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功！");
            this.DialogVisible = false;
            this.getRentProducts();
            loading.close();
          } else {
            this.$message.error("修改失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    delRentProducts(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delRentProduct(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getRentProducts();
          loading.close();
        } else {
          this.$message.error("删除失败！" + res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.getRentProducts();
    this.getEnameLists();
  },
};
</script>
