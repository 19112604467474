<template>
  <div>
    <el-table :data="orderList" v-loading="loading" style="width: 100%">
      <!-- <el-table-column prop="id" label="ID"> </el-table-column> -->
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="ehall" label="展馆号"> </el-table-column>
      <el-table-column prop="employee_name" label="受训人姓名">
      </el-table-column>
      <el-table-column prop="employee_phone" label="受训人电话">
      </el-table-column>
      <el-table-column prop="traning_id" label="受训标题"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="修改时间">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time">
            {{ formatDate(scope.row.update_time) }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="openDialog(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delPrestoreConfigs(scope.row.id)"
            >
              <el-button slot="reference" type="danger" style="margin-left: 10px"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column> -->
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 巡查内容 -->
    <el-dialog title="巡查内容" :visible.sync="dialogVisible" width="30%">
      <span>{{ form.content }}</span>
    </el-dialog>
  </div>
</template>

<script>
import { getSafetyTraningLog } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      orderList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogVisible: false,
      form: [],
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handlePageChange(page) {
      this.page = page;
      this.getSafetyTraningLogs(); // 请求新页面的数据
    },
    getSafetyTraningLogs() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getSafetyTraningLog(token, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.orderList);
        } else {
          this.$message.error("获取配置失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getSafetyTraningLogs();
  },
};
</script>

<style></style>
